import * as yup from "yup";
import { TextFormField } from "../../../../components/textform/TextFormField.tsx";
import { Formik, Field, Form } from "formik";
import moment from "moment";

export const validation = yup.object().shape({
  preparationDateAndTime: yup.string().required("Preparation date is required"),
  messageType: yup.string().required("Message type is required"),
  LRN: yup.string().required("LRN is required"),
  customsOfficeOfExportReferenceNumber: yup
    .string()
    .required("Customs office of export reference number is required"),
  declarantIdentificationNumber: yup
    .string()
    .required("Declarant identification number is required"),
  declarantName: yup.string().required("Declarant name is required"),
  declarantAddressStreetAndNumber: yup
    .string()
    .required("Declarant address street and number is required"),
  declarantAddressPostcode: yup
    .string()
    .required("Declarant address postcode is required"),
  declarantAddressCity: yup
    .string()
    .required("Declarant address city is required"),
  declarantAddressCountry: yup
    .string()
    .required("Declarant address country is required"),
  consignmentContainerIndicator: yup
    .string()
    .required("Consignment container indicator is required"),
  transportEquipmentSequenceNumber: yup
    .string()
    .required("Transport equipment sequence number is required"),
  transportEquipmentNumberOfSeals: yup
    .string()
    .required("Transport equipment number of seals is required"),
  sealSequenceNumber: yup.string().required("Seal sequence number is required"),
  sealIdentifier: yup.string().required("Seal identifier is required"),
  goodsReferenceSequenceNumber: yup
    .string()
    .required("Goods reference sequence number is required"),
  declarationGoodsItemNumber: yup
    .string()
    .required("Declaration goods item number is required"),
  locationOfGoodsTypeOfLocation: yup
    .string()
    .required("Location of goods type of location is required"),
  locationOfGoodsQualifierOfIdentification: yup
    .string()
    .required("Location of goods qualifier of identification is required"),
  customsOfficeReferenceNumber: yup
    .string()
    .required("Customs office reference number is required"),
  departureTransportMeansSequenceNumber: yup
    .string()
    .required("Departure transport means sequence number is required"),
  departureTransportMeansTypeOfIdentification: yup
    .string()
    .required("Departure transport means type of identification is required"),
  departureTransportMeansIdentificationNumber: yup
    .string()
    .required("Departure transport means identification number is required"),
  departureTransportMeansNationality: yup
    .string()
    .required("Departure transport nationality is required"),
});

export default function IE511({ submitForm, data }) {
  const form = [
    {
      name: "messageSender",
      label: "Message Sender",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageRecipient",
      label: "Message Recipient",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "preparationDateAndTime",
      label: "Preparation Date and Time",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageIdentification",
      label: "Message Identification",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageType",
      label: "Message Type",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "LRN",
      label: "LRN",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsOfficeOfExportReferenceNumber",
      label: "Customs Office of Export Reference Number",
      component: TextFormField,
      disabled: false,
      style: {
        width: "32%",
      },
    },
    {
      name: "declarantIdentificationNumber",
      label: "Declarant Identification Number",
      component: TextFormField,
      disabled: false,
      style: {
        width: "32%",
      },
    },
    {
      name: "declarantName",
      label: "Declarant Name",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "declarantAddressStreetAndNumber",
      label: "Declarant Address Street and Number",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "declarantAddressPostcode",
      label: "Declarant Address Postcode",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "declarantAddressCity",
      label: "Declarant Address City",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "declarantAddressCountry",
      label: "Declarant Address Country",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "consignmentContainerIndicator",
      label: "Consignment Container Indicator",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "consignmentInlandModeofTransport",
      label: "Consignment Inland Mode of Transport",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "transportEquipmentSequenceNumber",
      label: "Transport Equipment Sequence Number",
      component: TextFormField,
      disabled: false,
      style: {
        width: "32%",
      },
    },
    {
      name: "transportEquipmentNumberOfSeals",
      label: "Transport Equipment Number of Seals",
      component: TextFormField,
      disabled: false,
      style: {
        width: "32%",
      },
    },
    {
      name: "sealSequenceNumber",
      label: "Seal Sequence Number",
      component: TextFormField,
      disabled: false,
      style: {
        width: "32%",
      },
    },
    {
      name: "sealIdentifier",
      label: "Seal Identifier",
      component: TextFormField,
      disabled: false,
      style: {
        width: "32%",
      },
    },
    {
      name: "goodsReferenceSequenceNumber",
      label: "Goods Reference Sequence Number",
      component: TextFormField,
      disabled: false,
      style: {
        width: "32%",
      },
    },
    {
      name: "declarationGoodsItemNumber",
      label: "Declaration Goods Item Number",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "locationOfGoodsTypeOfLocation",
      label: "Location of Goods Type of Location",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "locationOfGoodsQualifierOfIdentification",
      label: "Location of Goods Qualifier of Identification",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsOfficeReferenceNumber",
      label: "Customs Office Reference Number",
      component: TextFormField,
      disabled: false,
      style: {
        width: "32%",
      },
    },
    {
      name: "departureTransportMeansSequenceNumber",
      label: "Departure Transport Means Sequence Number",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "departureTransportMeansTypeOfIdentification",
      label: "Departure Transport Means Type of Identification",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "departureTransportMeansIdentificationNumber",
      label: "Departure Transport Means Identification Number",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "departureTransportMeansNationality",
      label: "Departure Transport Nationality",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
  ];

  let departureTransportMeans;
  if (data.departure_transport_means) {
    departureTransportMeans = JSON.parse(data.departure_transport_means);
  }
  return (
    <div>
      <Formik
        validationSchema={validation}
        initialValues={{
          messageSender: data.message_sender,
          messageRecipient: data.message_recipient,
          preparationDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
          messageIdentification: data.message_identification,
          messageType: "CC511C",
          LRN: data.DECLARATION.lrn,
          customsOfficeOfExportReferenceNumber:
            data.customs_office_reference_number,
          declarantIdentificationNumber: data.declarant_identification_number,
          declarantName: "",
          declarantAddressStreetAndNumber: "",
          declarantAddressPostcode: "",
          declarantAddressCity: "",
          declarantAddressCountry: "",
          consignmentContainerIndicator: data.container_indicator,
          consignmentInlandModeofTransport: data.inland_mode_of_transport,
          transportEquipmentSequenceNumber:
            data.transport_equipment_sequence_number,
          transportEquipmentNumberOfSeals: data.number_of_seals,
          sealSequenceNumber: data.seal_sequence_number,
          sealIdentifier: data.seal_identifier,
          goodsReferenceSequenceNumber: data.goods_reference_sequence_number,
          declarationGoodsItemNumber: data.GoodsItem.length.toString(),
          locationOfGoodsTypeOfLocation: data.type_of_location,
          locationOfGoodsQualifierOfIdentification:
            data.qualifier_of_identification,
          customsOfficeReferenceNumber: data.customs_office_reference_number,
          departureTransportMeansSequenceNumber:
            departureTransportMeans[0].SequenceNumber,
          departureTransportMeansTypeOfIdentification:
            departureTransportMeans[0].TypeOfIdentification,
          departureTransportMeansIdentificationNumber:
            departureTransportMeans[0].IdentificationNumber,
          departureTransportMeansNationality:
            departureTransportMeans[0].Nationality,
        }}
        onSubmit={(values) => {
          submitForm(values);
        }}
      >
        {() => (
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBlock: 50,
            }}
          >
            <div className="form-position">
              {form.map((snapshot, key) => {
                return <Field {...snapshot} key={key} />;
              })}
            </div>
            <button
              style={{
                paddingTop: 10,
                alignSelf: "center",
                width: "35%",
                paddingBlock: 10,
              }}
              className="submit-form"
              type="submit"
            >
              Submit IE511 Declaration
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
