import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PopSnackbar from "../../../components/snackbar/Snackbar";
import IE507 from "./ie_components/IE507";
import IE590 from "./ie_components/IE590";
import {
  fetchDeclarationData,
  CreateDeclarationS2S507,
  CreateDeclarationS2S590,
  CreateDeclarationS2S596,
  CreateDeclarationS2S511,
  CreateDeclarationS2S547,
  CreateDeclarationS2S583,
  CreateDeclarationS2S514,
  CreateDeclarationS2S513,
  CreateDeclarationS2S520,
  CreateDeclarationS2S554,
  CreateDeclarationS2S573,
  CreateDeclarationS2S614,
  CreateDeclarationS2S613,
} from "../../../services/create_export_s2s";
import IE596 from "./ie_components/IE596";
import IE511 from "./ie_components/IE511";
import IE547 from "./ie_components/IE547";
import IE513 from "./ie_components/IE513";
import IE514 from "./ie_components/IE514";
import IE583 from "./ie_components/IE583";
import IE520 from "./ie_components/IE520";
import IE613 from "./ie_components/IE613";
import IE614 from "./ie_components/IE614";
import IE573 from "./ie_components/IE573";
import IE554 from "./ie_components/IE554";

export default function UploadDocS2S({ selectedOfficeID }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [description, setDescription] = useState("");
  const [data, setData] = useState({});

  const { declarationId, ieType, ieMessage } = location.state || {};

  async function submitForm(values) {
    try {
      setLoading(true);
      const jsonValues = JSON.stringify(values);
      let response;

      switch (ieType) {
        case "IE507":
          response = await CreateDeclarationS2S507(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE590":
          response = await CreateDeclarationS2S590(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE596":
          response = await CreateDeclarationS2S596(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE511":
          response = await CreateDeclarationS2S511(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE547":
          response = await CreateDeclarationS2S547(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE513":
          response = await CreateDeclarationS2S513(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE514":
          response = await CreateDeclarationS2S514(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE583":
          response = await CreateDeclarationS2S583(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE520":
          response = await CreateDeclarationS2S520(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE613":
          response = await CreateDeclarationS2S613(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE614":
          response = await CreateDeclarationS2S614(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE573":
          response = await CreateDeclarationS2S573(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
        case "IE554":
          response = await CreateDeclarationS2S554(
            selectedOfficeID,
            jsonValues,
            data.declaration_id
          );
          break;
      }

      if (response.status !== "OK") {
        throw new Error(
          response.error || "Something went wrong, please try again later."
        );
      }

      handleStatus(
        "Declaration has been successfully submitted",
        setError,
        setDescription
      );

      getDeclarationData();
    } catch (error) {
      handleStatus(error.message, setError, setDescription);
    } finally {
      navigate(-1);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!declarationId || !ieType) {
      navigate(-1);
    }

    getDeclarationData();
  }, []);

  async function getDeclarationData() {
    try {
      setLoading(true);
      const response = await fetchDeclarationData(
        declarationId,
        selectedOfficeID
      );
      if (response.status !== "OK") {
        throw new Error(
          response.error || "Something went wrong, please try again later."
        );
      }
      setData(response.data);
    } catch (error) {
      handleStatus(error.message, setError, setDescription);
    } finally {
      setLoading(false);
    }
  }

  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  const switchIEDeclaration = (ieType) => {
    switch (ieType) {
      case "IE507":
        return <IE507 data={data} submitForm={submitForm} />;
      case "IE590":
        return <IE590 data={data} submitForm={submitForm} />;
      case "IE596":
        return <IE596 data={data} submitForm={submitForm} />;
      case "IE511":
        return <IE511 data={data} submitForm={submitForm} />;
      case "IE547":
        return <IE547 data={data} submitForm={submitForm} />;
      case "IE513":
        return <IE513 data={data} submitForm={submitForm} />;
      case "IE514":
        return <IE514 data={data} submitForm={submitForm} />;
      case "IE583":
        return <IE583 data={data} submitForm={submitForm} />;
      case "IE520":
        return <IE520 data={data} submitForm={submitForm} />;
      case "IE613":
        return <IE613 data={data} submitForm={submitForm} />;
      case "IE614":
        return <IE614 data={data} submitForm={submitForm} />;
      case "IE573":
        return <IE573 data={data} submitForm={submitForm} />;
      case "IE554":
        return <IE554 data={data} submitForm={submitForm} />;
    }
  };

  if (!declarationId || !ieType) {
    return <div>Work in progress!</div>;
  }

  return (
    <div>
      <h1 style={{ fontFamily: "SeagoeUIBold" }}>
        {ieType} - {ieMessage.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
      </h1>

      {error && <PopSnackbar message={description} />}

      {loading ? (
        <CircularProgress style={{ alignSelf: "center" }} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
            marginTop: 20,
            backgroundColor: "transparent",
          }}
        >
          {switchIEDeclaration(ieType)}
        </div>
      )}
    </div>
  );
}
