import * as yup from "yup";
import { TextFormField } from "../../../../components/textform/TextFormField.tsx";
import { Formik, Field, Form } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import StyledDropzone from "../../../../components/dropzone/dropzone.js";

export const validation = yup.object().shape({
  messageSender: yup.string().required("Message sender is required"),
  messageRecipient: yup.string().required("Message recipient is required"),
  preparationDateAndTime: yup.string().required("Preparation date is required"),
  messageIdentification: yup
    .string()
    .required("Message identification is required"),
  messageType: yup.string().required("Message type is required"),
  correlationIdentifier: yup
    .string()
    .required("Correlation identifier is required"),
  LRN: yup.string().required("LRN is required"),
});

export default function IE596({ submitForm, data }) {
  const [requestedDocs, setRequestedDocs] = useState([]);
  const [files, setFiles] = useState([]);
  // const [fileContentTypes, setFileContentTypes] = useState({});

  const form = [
    {
      name: "messageSender",
      label: "Message Sender",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageRecipient",
      label: "Message Recipient",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "preparationDateAndTime",
      label: "Preparation Date and Time",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageIdentification",
      label: "Message Identification",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageType",
      label: "Message Type",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "correlationIdentifier",
      label: "Correlation Identifier",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "LRN",
      label: "LRN",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
  ];

  useEffect(() => {
    if (data) {
      const documents = xmlToRequestedDocument(data);
      setRequestedDocs(documents);
    }
  }, [data]);

  const xmlToRequestedDocument = (value) => {
    const lastLog =
      value.DECLARATION.S2SExportDeclarationLogs.slice(-1)[0].xml_body;
    const documents = parseRequestedDocuments(lastLog);

    if (documents.length < 1) {
      return <div>No documents required</div>;
    }
    return documents;
  };

  function parseRequestedDocuments(xml) {
    // Parse the XML string
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, "text/xml");

    // Find all RequestedDocument elements
    const requestedDocs = xmlDoc.getElementsByTagName("RequestedDocument");

    // Extract and store data from each RequestedDocument
    const documents = Array.from(requestedDocs).map((doc) => ({
      sequenceNumber: doc.getElementsByTagName("sequenceNumber")[0].textContent,
      documentType: doc.getElementsByTagName("documentType")[0].textContent,
      description: doc.getElementsByTagName("description")[0].textContent,
    }));

    return documents;
  }

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64FileContent = reader.result.split(",")[1];
        resolve({ base64: base64FileContent, name: file.name });
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  const handleFileUpload = (index, acceptedFiles) => {
    const updatedFiles = [...files];
    const filePromises = acceptedFiles.map((file) => convertFileToBase64(file));

    Promise.all(filePromises).then((encodedFiles) => {
      updatedFiles[index] = encodedFiles;
      setFiles(updatedFiles);
    });
  };

  const handleSubmit = (values) => {
    const formattedRequestedDocs = requestedDocs.map((doc, index) => ({
      sequenceNumber: doc.sequenceNumber,
      documentType: doc.documentType,
      uploadedFile: (files[index] || []).map((file) => ({
        fileContent: file.base64,
        fileName: file.name,
        fileContentType: "",
      })),
    }));

    const submissionData = {
      ...values,
      requestedDocument: formattedRequestedDocs,
    };

    submitForm(submissionData);
  };

  const handleDocumentChange = (index, field, value) => {
    const updatedDocs = [...requestedDocs];
    updatedDocs[index][field] = value;
    setRequestedDocs(updatedDocs);
  };

  return (
    <div>
      <Formik
        validationSchema={validation}
        initialValues={{
          messageSender: data.message_sender,
          messageRecipient: data.message_recipient,
          preparationDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
          messageIdentification: data.message_identification,
          messageType: "CC596C",
          correlationIdentifier: data.message_identification,
          LRN: data.DECLARATION.lrn,
        }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBlock: 50,
            }}
          >
            <div className="form-position">
              {form.map((snapshot, key) => (
                <Field {...snapshot} key={key} />
              ))}
            </div>
            {requestedDocs.length > 0 && (
              <>
                <div
                  style={{
                    marginTop: "30px",
                    fontFamily: "SeagoeUIBold",
                    fontSize: "20px",
                  }}
                >
                  Incarca documentele necesare
                </div>

                {requestedDocs &&
                  requestedDocs.map((doc, index) => (
                    <div
                      key={index}
                      style={{
                        marginTop: "30px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        rowGap: "1vw",
                      }}
                    >
                      <div style={{ display: "flex", columnGap: "1vw" }}>
                        <TextField
                          type="text"
                          id="outlined-basic"
                          variant="outlined"
                          disabled={true}
                          style={{
                            width: "50%",
                          }}
                          InputProps={{
                            sx: { borderRadius: 2 },
                          }}
                          value={doc.sequenceNumber}
                          onChange={(e) =>
                            handleDocumentChange(
                              index,
                              "sequenceNumber",
                              e.target.value
                            )
                          }
                        />
                        <TextField
                          type="text"
                          id="outlined-basic"
                          variant="outlined"
                          disabled={true}
                          style={{
                            width: "50%",
                          }}
                          InputProps={{
                            sx: { borderRadius: 2 },
                          }}
                          value={doc.documentType}
                          onChange={(e) =>
                            handleDocumentChange(
                              index,
                              "documentType",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <StyledDropzone
                        multiple={false}
                        onDrop={(acceptedFiles) =>
                          handleFileUpload(index, acceptedFiles)
                        }
                        files={files[index] || []}
                        title={
                          files[index]?.length > 0
                            ? files[index][0].name
                            : "Drag 'n' drop file"
                        }
                      />
                    </div>
                  ))}
              </>
            )}
            <button
              style={{
                paddingTop: 10,
                alignSelf: "center",
                width: "35%",
                paddingBlock: 10,
              }}
              className="submit-form"
              type="submit"
            >
              Submit IE596 Declaration
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
