export const FUNCTIONALITIES = {
  exportDailyReport: "EXPORT_REPORT_DAILY",
  createExportDeclaration: "CREATE_EXPORT_DECLARATION",
  createExportDeclarationS2s: "CREATE_EXPORT_DECLARATION_S2S",
  importDailyReport: "IMPORT_REPORT_DAILY",
  importPlexusReport: "IMPORT_REPORT_PLEXUS",
  createImportDeclaration: "CREATE_IMPORT_DECLARATION",
  createImportH7Declaration: "CREATE_IMPORT_H7_DECLARATION",
  profileOffice: "PROFILE_OFFICE",
  Translations: "TRANSLATIONS",
  Duties: "DUTIES",
  createImportDeclarationS2S: "CREATE_IMPORT_DECLARATION_S2S",
};
