import * as yup from "yup";
import { TextFormField } from "../../../../components/textform/TextFormField.tsx";
import { Formik, Field, Form } from "formik";
import moment from "moment";
import { useState } from "react";
import { TextField } from "@mui/material";
import StyledDropzone from "../../../../components/dropzone/dropzone.js";

export const validation = yup.object().shape({
  messageSender: yup.string().required("Message sender is required"),
  messageRecipient: yup.string().required("Message recipient is required"),
  preparationDateAndTime: yup.string().required("Preparation date is required"),
  messageIdentification: yup
    .string()
    .required("Message identification is required"),
  messageType: yup.string().required("Message type is required"),
  correlationIdentifier: yup
    .string()
    .required("Correlation identifier is required"),
  LRN: yup.string().required("LRN is required"),
  responseDateAndTime: yup.string().required("Response date is required"),
  justification: yup.string().required("Justification is required"),
  customsOfficeOfExport: yup.string().required("Customs office is required"),
  declarant: yup.string().required("Declarant is required"),
});

export default function IE520({ submitForm, data }) {
  const [justificationDocs, setJustificationDocs] = useState([]);
  const [files, setFiles] = useState([]);

  const form = [
    {
      name: "messageSender",
      label: "Message Sender",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageRecipient",
      label: "Message Recipient",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "preparationDateAndTime",
      label: "Preparation Date and Time",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageIdentification",
      label: "Message Identification",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageType",
      label: "Message Type",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "correlationIdentifier",
      label: "Correlation Identifier",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "LRN",
      label: "LRN",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "MRN",
      label: "MRN",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "responseDateAndTime",
      label: "Response Date and Time",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "justification",
      label: "Justification",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "justificationCode",
      label: "Justification Code",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsOfficeOfExport",
      label: "Customs Office of Export",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "declarant",
      label: "Declarant",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
  ];

  const addNewDocument = () => {
    setJustificationDocs((prevDocs) => [
      ...prevDocs,
      {
        sequenceNumber: (prevDocs.length + 1).toString(),
        identificationNumber: "",
        description: "",
      },
    ]);
    setFiles((prevFiles) => [...prevFiles, []]);
  };

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64FileContent = reader.result.split(",")[1];
        resolve({ base64: base64FileContent, name: file.name });
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  const handleFileUpload = (index, acceptedFiles) => {
    const updatedFiles = [...files];
    const filePromises = acceptedFiles.map((file) => convertFileToBase64(file));

    Promise.all(filePromises).then((encodedFiles) => {
      updatedFiles[index] = encodedFiles;
      setFiles(updatedFiles);
    });
  };

  const handleDocumentChange = (index, field, value) => {
    setJustificationDocs((prevDocs) => {
      const updatedDocs = [...prevDocs];
      updatedDocs[index][field] = value;
      return updatedDocs;
    });
  };

  const handleSubmit = (values) => {
    const formattedJustificationDocs = justificationDocs.map((doc, index) => {
      const file = (files[index] || [])[0] || {};

      return {
        sequenceNumber: doc.sequenceNumber,
        identificationNumber: doc.identificationNumber,
        description: doc.description,
        uploadedFile: {
          fileContent: file.base64 || "",
          fileName: file.name || "",
          fileContentType: "",
        },
      };
    });

    const submissionData = {
      ...values,
      justificationDocuments: formattedJustificationDocs,
    };

    submitForm(submissionData);
  };

  return (
    <div>
      <Formik
        validationSchema={validation}
        initialValues={{
          messageSender: data.message_sender,
          messageRecipient: data.message_recipient,
          preparationDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
          messageIdentification: data.message_identification,
          messageType: "CC520C",
          correlationIdentifier: data.message_identification,
          LRN: data.DECLARATION.lrn,
          MRN: data.DECLARATION.mrn,
          responseDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
          justification: "",
          justificationCode: "",
          customsOfficeOfExport: data.customs_office_of_export,
          declarant: data.declarant_identification_number,
        }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBlock: 50,
            }}
          >
            <div className="form-position">
              {form.map((snapshot, key) => {
                return <Field {...snapshot} key={key} />;
              })}
            </div>
            <>
              <div
                style={{
                  marginTop: "30px",
                  fontFamily: "SeagoeUIBold",
                  fontSize: "20px",
                }}
              >
                Upload Justification Documents
              </div>

              {justificationDocs.map((doc, index) => (
                <div
                  key={index}
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    rowGap: "1vw",
                  }}
                >
                  <div style={{ display: "flex", columnGap: "1vw" }}>
                    <TextField
                      type="text"
                      variant="outlined"
                      disabled={true}
                      style={{
                        width: "20%",
                      }}
                      InputProps={{
                        sx: { borderRadius: 2 },
                      }}
                      value={doc.sequenceNumber}
                      onChange={(e) =>
                        handleDocumentChange(
                          index,
                          "sequenceNumber",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      type="text"
                      variant="outlined"
                      disabled={false}
                      style={{
                        width: "30%",
                      }}
                      InputProps={{
                        sx: { borderRadius: 2 },
                      }}
                      value={doc.identificationNumber}
                      onChange={(e) =>
                        handleDocumentChange(
                          index,
                          "identificationNumber",
                          e.target.value
                        )
                      }
                      placeholder="Identification Number"
                    />
                    <TextField
                      type="text"
                      variant="outlined"
                      disabled={false}
                      style={{
                        width: "30%",
                      }}
                      InputProps={{
                        sx: { borderRadius: 2 },
                      }}
                      value={doc.description}
                      onChange={(e) =>
                        handleDocumentChange(
                          index,
                          "description",
                          e.target.value
                        )
                      }
                      placeholder="Description"
                    />
                  </div>
                  <StyledDropzone
                    multiple={false}
                    onDrop={(acceptedFiles) =>
                      handleFileUpload(index, acceptedFiles)
                    }
                    files={files[index] || []}
                    title={
                      files[index]?.length > 0
                        ? files[index][0].name
                        : "Drag 'n' drop file"
                    }
                  />
                </div>
              ))}
              <button
                type="button"
                onClick={addNewDocument}
                style={{
                  color: "#fff",
                  backgroundColor: "black",
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
              >
                + Add Document
              </button>
            </>
            <button
              style={{
                paddingTop: 10,
                alignSelf: "center",
                width: "35%",
                paddingBlock: 10,
              }}
              className="submit-form"
              type="submit"
            >
              Submit IE520 Declaration
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
