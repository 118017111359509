import * as yup from "yup";
import { TextFormField } from "../../../../components/textform/TextFormField.tsx";
import { Formik, Field, Form } from "formik";
import moment from "moment";

export const validation = yup.object().shape({});

export default function IE554({ submitForm, data }) {
  const form = [];

  return (
    <div>
      <Formik
        validationSchema={validation}
        initialValues={{}}
        onSubmit={(values) => submitForm(values)}
      >
        {() => (
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBlock: 50,
            }}
          >
            <div className="form-position">
              {form.map((snapshot, key) => {
                return <Field {...snapshot} key={key} />;
              })}
            </div>
            <button
              style={{
                paddingTop: 10,
                alignSelf: "center",
                width: "35%",
                paddingBlock: 10,
              }}
              className="submit-form"
              type="submit"
            >
              Submit IE554 Declaration
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
