import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchDeclarationData } from "../../../services/create_export_s2s";
import IE515 from "./ie_components/IE515";
import CircularProgress from "@mui/material/CircularProgress";
import PopSnackbar from "../../../components/snackbar/Snackbar";
import IE615 from "./ie_components/IE615";
import IE570 from "./ie_components/IE570";

export const ViewDeclarationS2S = ({ selectedOfficeID }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { declarationId, ieType, ieMessage } = location.state || {};

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [description, setDescription] = useState("");
  const [data, setData] = useState({});

  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  async function getDeclarationData() {
    try {
      setLoading(true);
      const response = await fetchDeclarationData(
        declarationId,
        selectedOfficeID
      );
      if (response.status !== "OK") {
        throw new Error(
          response.error || "Something went wrong, please try again later."
        );
      }
      setData(response.data);
    } catch (error) {
      handleStatus(error.message, setError, setDescription);
    } finally {
      setLoading(false);
    }
  }

  const switchDeclaration = (ieType) => {
    switch (ieType) {
      case "CC515C":
        return <IE515 data={data} />;
      case "CC615C":
        return <IE615 data={data} />;
      case "CC570C":
        return <IE570 data={data} />;
      default:
        return "Work in progress!";
    }
  };

  useEffect(() => {
    if (!declarationId || !ieType) {
      navigate(-1);
    }

    getDeclarationData();
  }, []);

  if (!declarationId || !ieType) {
    return <div>Work in progress!</div>;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h1 style={{ fontFamily: "SeagoeUIBold" }}>{ieMessage}</h1>
      {loading ? (
        <CircularProgress style={{ alignSelf: "center" }} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
            marginTop: 20,
            backgroundColor: "transparent",
          }}
        >
          {switchDeclaration(ieType)}
        </div>
      )}
      {error && <PopSnackbar message={description} />}
      <button
        className="goback-btn"
        disabled={loading}
        onClick={() => navigate(-1)}
      >
        Go Back
      </button>
    </div>
  );
};
