import { TextFormField } from "../../../../components/textform/TextFormField.tsx";
import { Formik, Field, Form } from "formik";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function IE515({ data }) {
  const form = [
    {
      name: "messageSender",
      label: "Message Sender",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "messageRecipient",
      label: "Message Recipient",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "messageIdentification",
      label: "Message Identification",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "messageType",
      label: "Message Type",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "declarationType",
      label: "Declaration Type",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "additionalDeclarationType",
      label: "Additional Declaration Type",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "security",
      label: "Security",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "totalAmountInvoiced",
      label: "Total Amount Invoiced",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "invoiceCurrency",
      label: "Invoice Currency",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "customsOfficeOfExport",
      label: "Customs Office Of Export",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "customsOfficePoint",
      label: "Customs Office Point",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "customsOfficeOfExitDeclared",
      label: "Customs Office Of Exit Declared",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "exporterIdentificationNumber",
      label: "Exporter Identification Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "declarantIdentificationNumber",
      label: "Declarant Identification Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "representativeIdentificationNumber",
      label: "Representative Identification Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "representativeStatus",
      label: "Representative Status",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "natureOfTransaction",
      label: "Nature Of Transaction",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "countryOfExport",
      label: "Country Of Export",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "countryOfDestination",
      label: "Country Of Destination",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "deliveryTermsIncotermCode",
      label: "Delivery Terms Incoterm Code",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "deliveryTermsLocation",
      label: "Delivery Terms Location",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "deliveryTermsCountry",
      label: "Delivery Terms Country",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "containerIndicator",
      label: "Container Indicator",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "inlandModeOfTransport",
      label: "Inland Mode Of Transport",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "modeOfTransportAtTheBorder",
      label: "Mode Of Transport At The Border",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "totalGrossMass",
      label: "Total Gross Mass",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consignorIdentificationNumber",
      label: "Consignor Identification Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consigneeName",
      label: "Consignee Name",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consigneeAddressStreetAndNumber",
      label: "Consignee Address Street And Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consigneeAddressPostcode",
      label: "Consignee Address Postcode",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consigneeAddressCity",
      label: "Consignee Address City",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consigneeAddressCountry",
      label: "Consignee Address Country",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "transportEquipmentSequenceNumber",
      label: "Transport Equipment Sequence Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "numberOfSeals",
      label: "Number Of Seals",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "sealSequenceNumber",
      label: "Seal Sequence Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "sealIdentifier",
      label: "Seal Identifier",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "goodsReferenceSequenceNumber",
      label: "Goods Reference Sequence Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "goodsReferenceDeclarationGoodsItemNumber",
      label: "Goods Reference Declaration Goods Item Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "typeOfLocation",
      label: "Type Of Location",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "qualifierOfIdentification",
      label: "Qualifier Of Identification",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "customsOfficeReferenceNumber",
      label: "Customs Office Reference Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "activeBorderTransportMeansTypeOfIdentification",
      label: "Active Border Transport Means Type Of Identification",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "activeBorderTransportMeansIdentificationNumber",
      label: "Active Border Transport Means Identification Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "activeBorderTransportMeansNationality",
      label: "Active Border Transport Means Nationality",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
  ];

  const goodsItemForm = [
    {
      name: "declarationGoodsItemNumber",
      label: "Declaration Goods Item Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "statisticalValue",
      label: "Statistical Value",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "countryOfExport",
      label: "Country Of Export",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "countryOfDestination",
      label: "Country Of Destination",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "requestedProcedure",
      label: "Requested Procedure",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "previousProcedure",
      label: "Previous Procedure",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "countryOfOrigin",
      label: "Country Of Origin",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "descriptionOfGoods",
      label: "Description Of Goods",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "harmonizedSystemCode",
      label: "Harmonized System Code",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "combinedNomenclatureCode",
      label: "Combined Nomenclature Code",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "grossMass",
      label: "Gross Mass",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "netMass",
      label: "Net Mass",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "packagingSequenceNumber",
      label: "Packaging Sequence Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "packagingTypeOfPackages",
      label: "Packaging Type Of Packages",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "packagingNumberOfPackages",
      label: "Packaging Number Of Packages",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "packagingShippingMarks",
      label: "Packaging Shipping Marks",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "articleValueCurrency",
      label: "Article Value Currency",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "articleValueCurrencyRate",
      label: "Article Value Currency Rate",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "articleValueTotalInRon",
      label: "Article Value Total In RON",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "articleValueValue",
      label: "Article Value",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "statValueCalc",
      label: "Stat Value Calc",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
  ];

  let departureTransportMeans = JSON.parse(data.departure_transport_means);
  let countryOfRoutingOfConsignment = JSON.parse(
    data.country_of_routing_of_consignment
  );

  const parseDocumentArray = (dataString) => {
    try {
      return JSON.parse(dataString);
    } catch (error) {
      console.error("Failed to parse JSON", error);
      return [];
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          messageSender: data.message_sender,
          messageRecipient: data.message_recipient,
          messageIdentification: data.message_identification,
          messageType: data.message_type,
          appVersion: data.app_version,
          declarationType: data.declaration_type,
          additionalDeclarationType: data.additional_declaration_type,
          security: data.security,
          totalAmountInvoiced: data.total_amount_invoiced,
          invoiceCurrency: data.invoice_currency,
          customsOfficeOfExport: data.customs_office_of_export,
          customsOfficePoint: data.customs_office_point,
          customsOfficeOfExitDeclared: data.customs_office_of_exit_declared,
          exporterIdentificationNumber: data.exporter_identification_number,
          declarantIdentificationNumber: data.declarant_identification_number,
          representativeIdentificationNumber:
            data.representative_identification_number,
          representativeStatus: data.representative_status,
          natureOfTransaction: data.nature_of_transaction,
          countryOfExport: data.country_of_export,
          countryOfDestination: data.country_of_destination,
          deliveryTermsIncotermCode: data.delivery_terms_incoterm_code,
          deliveryTermsLocation: data.delivery_terms_location,
          deliveryTermsCountry: data.delivery_terms_country,
          containerIndicator: data.container_indicator,
          inlandModeOfTransport: data.inland_mode_of_transport,
          modeOfTransportAtTheBorder: data.mode_of_transport_at_the_border,
          totalGrossMass: data.total_gross_mass,
          consignorIdentificationNumber: data.consignor_identification_number,
          consigneeName: data.consignee_name,
          consigneeAddressStreetAndNumber:
            data.consignee_address_street_and_number,
          consigneeAddressPostcode: data.consignee_address_postcode,
          consigneeAddressCity: data.consignee_address_city,
          consigneeAddressCountry: data.consignee_address_country,
          transportEquipmentSequenceNumber:
            data.transport_equipment_sequence_number,
          numberOfSeals: data.number_of_seals,
          sealSequenceNumber: data.seal_sequence_number,
          sealIdentifier: data.seal_identifier,
          goodsReferenceSequenceNumber: data.goods_reference_sequence_number,
          goodsReferenceDeclarationGoodsItemNumber:
            data.goods_reference_declaration_goods_item_number,
          typeOfLocation: data.type_of_location,
          qualifierOfIdentification: data.qualifier_of_identification,
          customsOfficeReferenceNumber: data.customs_office_reference_number,
          departureTransportMeans: departureTransportMeans.map((item) => ({
            sequenceNumber: item.SequenceNumber,
            typeOfIdentification: item.TypeOfIdentification,
            identificationNumber: item.IdentificationNumber,
            nationality: item.Nationality,
          })),
          countryOfRoutingOfConsignment: countryOfRoutingOfConsignment.map(
            (item) => ({
              sequenceNumber: item.SequenceNumber,
              country: item.Country,
            })
          ),
          activeBorderTransportMeansTypeOfIdentification:
            data.active_border_transport_means_type_of_identification,
          activeBorderTransportMeansIdentificationNumber:
            data.active_border_transport_means_identification_number,
          activeBorderTransportMeansNationality:
            data.active_border_transport_means_nationality,
          goodsItems: data.GoodsItem.map((item) => ({
            additionalReference: parseDocumentArray(item.additional_reference),
            articleValueCurrency: item.article_value_currency,
            articleValueCurrencyRate: item.article_value_currency_rate,
            articleValueTotalInRon: item.article_value_total_in_ron,
            articleValueValue: item.article_value_value,
            combinedNomenclatureCode: item.combined_nomenclature_code,
            countryOfDestination: item.country_of_destination,
            countryOfExport: item.country_of_export,
            countryOfOrigin: item.country_of_origin,
            declarationGoodsItemNumber: item.declaration_goods_item_number,
            descriptionOfGoods: item.description_of_goods,
            grossMass: item.gross_mass,
            harmonizedSystemCode: item.harmonized_system_code,
            netMass: item.net_mass,
            packagingNumberOfPackages: item.packaging_number_of_packages,
            packagingSequenceNumber: item.packaging_sequence_number,
            packagingShippingMarks: item.packaging_shipping_marks,
            packagingTypeOfPackages: item.packaging_type_of_packages,
            previousDocument: parseDocumentArray(item.previous_document),
            previousProcedure: item.previous_procedure,
            requestedProcedure: item.requested_procedure,
            statValueCalc: item.stat_value_calc,
            statisticalValue: item.statistical_value,
            supportingDocument: parseDocumentArray(item.supporting_document),
            transportDocument: parseDocumentArray(item.transport_document),
          })),
        }}
      >
        {({ values }) => (
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBlock: 50,
            }}
          >
            <div className="form-position">
              {form.map(
                (field, index) =>
                  values[field.name] && <Field {...field} key={index} />
              )}
            </div>

            {values.departureTransportMeans &&
              values.departureTransportMeans.length > 0 && (
                <div style={{ width: "100%", marginTop: 20 }}>
                  <h3 style={{ fontFamily: "SeagoeUIBold" }}>
                    Departure Transport Means
                  </h3>
                  <ul style={{ display: "flex", columnGap: 50 }}>
                    {values.departureTransportMeans.map((item, index) => (
                      <li key={index}>
                        <p style={{ fontFamily: "SeagoeUI" }}>
                          Sequence Number: {item.sequenceNumber}
                        </p>
                        <p style={{ fontFamily: "SeagoeUI" }}>
                          Type Of Identification: {item.typeOfIdentification}
                        </p>
                        <p style={{ fontFamily: "SeagoeUI" }}>
                          Identification Number: {item.identificationNumber}
                        </p>
                        <p style={{ fontFamily: "SeagoeUI" }}>
                          Nationality: {item.nationality}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

            {values.countryOfRoutingOfConsignment &&
              values.countryOfRoutingOfConsignment.length > 0 && (
                <div style={{ width: "100%", marginTop: 20 }}>
                  <h3 style={{ fontFamily: "SeagoeUIBold" }}>
                    Country of Routing of Consignment
                  </h3>
                  <ul style={{ display: "flex", columnGap: 50 }}>
                    {values.countryOfRoutingOfConsignment.map((item, index) => (
                      <li key={index}>
                        <p style={{ fontFamily: "SeagoeUI" }}>
                          Country: {item.country}
                        </p>
                        <p style={{ fontFamily: "SeagoeUI" }}>
                          Sequence Number: {item.sequenceNumber}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

            {values.goodsItems.map(
              (goodsItem, index) =>
                goodsItem && (
                  <Accordion
                    style={{ width: "100%", marginTop: 20 }}
                    key={index}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`goods-item-content-${index}`}
                      id={`goods-item-header-${index}`}
                    >
                      <div>Goods Item {index + 1}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="form-position">
                        {goodsItemForm.map(
                          (field, fieldIndex) =>
                            goodsItem[field.name] && (
                              <Field
                                {...field}
                                name={`goodsItems.${index}.${field.name}`}
                                key={fieldIndex}
                                value={goodsItem[field.name]}
                              />
                            )
                        )}
                      </div>
                    </AccordionDetails>
                    <div style={{ padding: 20 }}>
                      {goodsItem.previousDocument &&
                        goodsItem.previousDocument.length > 0 && (
                          <div style={{ width: "100%", marginTop: 20 }}>
                            <h4 style={{ fontFamily: "SeagoeUIBold" }}>
                              Previous Documents
                            </h4>
                            <ul style={{ display: "flex", columnGap: 50 }}>
                              {goodsItem.previousDocument.map(
                                (doc, docIndex) => (
                                  <li key={docIndex}>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Sequence Number: {doc.SequenceNumber}
                                    </p>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Reference: {doc.Reference}
                                    </p>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Type: {doc.Type}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                      {goodsItem.supportingDocument &&
                        goodsItem.supportingDocument.length > 0 && (
                          <div style={{ width: "100%", marginTop: 20 }}>
                            <h4 style={{ fontFamily: "SeagoeUIBold" }}>
                              Supporting Documents
                            </h4>
                            <ul style={{ display: "flex", columnGap: 50 }}>
                              {goodsItem.supportingDocument.map(
                                (doc, docIndex) => (
                                  <li key={docIndex}>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Sequence Number: {doc.SequenceNumber}
                                    </p>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Reference: {doc.Reference}
                                    </p>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Type: {doc.Type}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                      {goodsItem.transportDocument &&
                        goodsItem.transportDocument.length > 0 && (
                          <div style={{ width: "100%", marginTop: 20 }}>
                            <h4 style={{ fontFamily: "SeagoeUIBold" }}>
                              Transport Documents
                            </h4>
                            <ul style={{ display: "flex", columnGap: 50 }}>
                              {goodsItem.transportDocument.map(
                                (doc, docIndex) => (
                                  <li key={docIndex}>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Sequence Number: {doc.SequenceNumber}
                                    </p>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Reference: {doc.Reference}
                                    </p>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Type: {doc.Type}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                      {goodsItem.additionalReference &&
                        goodsItem.additionalReference.length > 0 && (
                          <div style={{ width: "100%", marginTop: 20 }}>
                            <h4 style={{ fontFamily: "SeagoeUIBold" }}>
                              Additional Reference
                            </h4>
                            <ul style={{ display: "flex", columnGap: 50 }}>
                              {goodsItem.additionalReference.map(
                                (doc, docIndex) => (
                                  <li key={docIndex}>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Sequence Number: {doc.SequenceNumber}
                                    </p>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Reference: {doc.Reference}
                                    </p>
                                    <p style={{ fontFamily: "SeagoeUI" }}>
                                      Type: {doc.Type}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                    </div>
                  </Accordion>
                )
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
