import * as yup from "yup";
import { TextFormField } from "../../../../components/textform/TextFormField.tsx";
import { Formik, Field, Form } from "formik";
import moment from "moment";

export const validation = yup.object().shape({
  messageSender: yup.string().required("Message sender is required"),
  messageRecipient: yup.string().required("Message recipient is required"),
  preparationDateAndTime: yup
    .string()
    .required("Preparation date and time is required"),
  messageIdentification: yup
    .string()
    .required("Message identification is required"),
  messageType: yup.string().required("Message type is required"),
  correlationIdentifier: yup
    .string()
    .required("Correlation identifier is required"),
  MRN: yup.string().required("MRN is required"),
  arrivalNotificationDateAndTime: yup
    .string()
    .required("Arrival notification date and time is required"),
  storingFlag: yup.string().required("Storing flag is required"),
  discrepanciesExist: yup.string().required("Discrepancies exist is required"),
  customsOfficeOfExitActual: yup
    .string()
    .required("Customs office of exit actual is required"),
  exitCarrierIdentificationNumber: yup
    .string()
    .required("Exit carrier identification number is required"),
  exitCarrierName: yup.string().required("Exit carrier name is required"),
  exitAddressStreetAndNumber: yup
    .string()
    .required("Address street and number is required"),
  exitAddressPostcode: yup.string().required("Address postcode is required"),
  exitAddressCity: yup.string().required("Address city is required"),
  exitAddressCountry: yup.string().required("Address country is required"),
  locationOfGoodsSequenceNumber: yup
    .string()
    .required("Location of goods sequence number is required"),
  locationOfGoodsTypeOfLocation: yup
    .string()
    .required("Location of goods type of location is required"),
  locationOfGoodsQualifierOfIdentification: yup
    .string()
    .required("Location of goods qualifier of identification is required"),
  customsOfficeReferenceNumber: yup
    .string()
    .required("Customs office reference number is required"),
  activeBorderTransportMeansTypeOfIdentification: yup
    .string()
    .required(
      "Active border transport means type of identification is required"
    ),
  activeBorderTransportMeansIdentificationNumber: yup
    .string()
    .required(
      "Active border transport means identification number is required"
    ),
  activeBorderTransportMeansNationality: yup
    .string()
    .required("Active border transport means nationality is required"),
  declarationGoodsItemNumber: yup
    .string()
    .required("Declaration goods item number is required"),
});

export default function IE507({ submitForm, data }) {
  const form = [
    {
      name: "messageSender",
      label: "Message Sender",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageRecipient",
      label: "Message Recipient",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "preparationDateAndTime",
      label: "Preparation Date and Time",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageIdentification",
      label: "Message Identification",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageType",
      label: "Message Type",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "correlationIdentifier",
      label: "Correlation Identifier",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "MRN",
      label: "MRN",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "arrivalNotificationDateAndTime",
      label: "Arrival Notification Date and Time",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "storingFlag",
      label: "Storing Flag",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "discrepanciesExist",
      label: "Discrepancies Exist",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsOfficeOfExitActual",
      label: "Customs Office of Exit Actual",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "exitCarrierIdentificationNumber",
      label: "Exit Carrier Identification Number",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "exitCarrierName",
      label: "Exit Carrier Name",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "exitAddressStreetAndNumber",
      label: "Exit Address Street and Number",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "exitAddressPostcode",
      label: "Exit Address Postcode",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "exitAddressCity",
      label: "Exit Address City",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "exitAddressCountry",
      label: "Exit Address Country",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "locationOfGoodsSequenceNumber",
      label: "Location of Goods Sequence Number",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "locationOfGoodsTypeOfLocation",
      label: "Location of Goods Type of Location",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "locationOfGoodsQualifierOfIdentification",
      label: "Location of Goods Qualifier of Identification",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsOfficeReferenceNumber",
      label: "Customs Office Reference Number",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "activeBorderTransportMeansTypeOfIdentification",
      label: "Active Border Transport Means Type of Identification",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "activeBorderTransportMeansIdentificationNumber",
      label: "Active Border Transport Means Identification Number",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "activeBorderTransportMeansNationality",
      label: "Active Border Transport Means Nationality",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "declarationGoodsItemNumber",
      label: "Declaration Goods Item Number",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
  ];

  let departureTransportMeans;
  if (data.departure_transport_means) {
    departureTransportMeans = JSON.parse(data.departure_transport_means);
  }

  return (
    <div>
      <Formik
        validationSchema={validation}
        initialValues={
          data.message_type == "CC515C"
            ? {
                messageSender: data.message_sender,
                messageRecipient: data.message_recipient,
                preparationDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                messageIdentification: data.message_identification,
                messageType: "CC507C",
                correlationIdentifier: data.message_identification,
                MRN: data.DECLARATION.mrn,
                arrivalNotificationDateAndTime: moment().format(
                  "YYYY-MM-DDTHH:mm:ss"
                ),
                storingFlag: data.storing_flag,
                discrepanciesExist: "",
                customsOfficeOfExitActual: data.customs_office_of_exit_declared,
                exitCarrierIdentificationNumber: "",
                exitCarrierName: data.consignee_name,
                exitAddressStreetAndNumber:
                  data.consignee_address_street_and_number,
                exitAddressPostcode: data.consignee_address_postcode,
                exitAddressCity: data.consignee_address_city,
                exitAddressCountry: data.consignee_address_country,
                locationOfGoodsSequenceNumber: "1",
                locationOfGoodsTypeOfLocation: data.type_of_location,
                locationOfGoodsQualifierOfIdentification:
                  data.qualifier_of_identification,
                customsOfficeReferenceNumber:
                  data.customs_office_reference_number,
                activeBorderTransportMeansTypeOfIdentification:
                  departureTransportMeans[0].TypeOfIdentification,
                activeBorderTransportMeansIdentificationNumber:
                  departureTransportMeans[0].IdentificationNumber,
                activeBorderTransportMeansNationality:
                  departureTransportMeans[0].Nationality,
                declarationGoodsItemNumber: data.GoodsItem.length.toString(),
              }
            : {
                messageSender: data.message_sender,
                messageRecipient: data.message_recipient,
                preparationDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                messageIdentification: data.message_identification,
                messageType: "CC507C",
                correlationIdentifier: data.message_identification,
                MRN: data.DECLARATION.mrn,
                arrivalNotificationDateAndTime: moment().format(
                  "YYYY-MM-DDTHH:mm:ss"
                ),
                storingFlag: data.export_operation_storing_flag,
                discrepanciesExist: "",
                customsOfficeOfExitActual:
                  data.customs_office_of_exit_declared_reference_number,
                exitCarrierIdentificationNumber: "",
                exitCarrierName: data.consignee_name,
                exitAddressStreetAndNumber:
                  data.consignee_address_street_and_number,
                exitAddressPostcode: data.consignee_address_postcode,
                exitAddressCity: data.consignee_address_city,
                exitAddressCountry: data.consignee_address_country,
                locationOfGoodsSequenceNumber: "1",
                locationOfGoodsTypeOfLocation:
                  data.location_of_goods_type_of_location,
                locationOfGoodsQualifierOfIdentification:
                  data.location_of_goods_qualifier_of_identification,
                customsOfficeReferenceNumber:
                  data.location_of_goods_customs_office_reference_number,
                activeBorderTransportMeansTypeOfIdentification: "",
                activeBorderTransportMeansIdentificationNumber: "",
                activeBorderTransportMeansNationality: "",
                declarationGoodsItemNumber:
                  data.consignment_item_declaration_goods_item_number,
              }
        }
        onSubmit={(values) => {
          submitForm(values);
        }}
      >
        {() => (
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBlock: 50,
            }}
          >
            <div className="form-position">
              {form.map((snapshot, key) => {
                return <Field {...snapshot} key={key} />;
              })}
            </div>
            <button
              style={{
                paddingTop: 10,
                alignSelf: "center",
                width: "35%",
                paddingBlock: 10,
              }}
              className="submit-form"
              type="submit"
            >
              Submit IE507 Declaration
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
