import { TextFormField } from "../../../../components/textform/TextFormField.tsx";
import { Formik, Field, Form } from "formik";

export default function IE570({ data }) {
  const form = [
    {
      name: "messageSender",
      label: "Message Sender",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "messageRecipient",
      label: "Message Recipient",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "messageIdentification",
      label: "Message Identification",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "messageType",
      label: "Message Type",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "exportOperationStoringFlag",
      label: "Export Operation Storing Flag",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "customsOfficeOfExitDeclaredReferenceNumber",
      label: "Customs Office of Exit Declared Reference Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "declarantIdentificationNumber",
      label: "Declarant Identification Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consignmentContainerIndicator",
      label: "Consignment Container Indicator",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "carrierIdentificationNumber",
      label: "Carrier Identification Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "additionalSupplyChainActorSequenceNumber",
      label: "Additional Supply Chain Actor Sequence Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "additionalSupplyChainActorRole",
      label: "Additional Supply Chain Actor Role",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "additionalSupplyChainActorIdentificationNumber",
      label: "Additional Supply Chain Actor Identification Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "locationOfGoodsTypeOfLocation",
      label: "Location of Goods Type of Location",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "locationOfGoodsQualifierOfIdentification",
      label: "Location of Goods Qualifier of Identification",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "locationOfGoodsCustomsOfficeReferenceNumber",
      label: "Location of Goods Customs Office Reference Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "transportDocumentSequenceNumber",
      label: "Transport Document Sequence Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "transportDocumentType",
      label: "Transport Document Type",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "transportDocumentReferenceNumber",
      label: "Transport Document Reference Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consignmentItemDeclarationGoodsItemNumber",
      label: "Consignment Item Declaration Goods Item Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
  ];

  return (
    <div>
      <Formik
        initialValues={{
          messageSender: data.message_sender,
          messageRecipient: data.message_recipient,
          messageIdentification: data.message_identification,
          messageType: data.message_type,
          exportOperationStoringFlag: data.export_operation_storing_flag,
          customsOfficeOfExitDeclaredReferenceNumber:
            data.customs_office_of_exit_declared_reference_number,
          declarantIdentificationNumber: data.declarant_identification_number,
          consignmentContainerIndicator: data.consignment_container_indicator,
          carrierIdentificationNumber: data.carrier_identification_number,
          additionalSupplyChainActorSequenceNumber:
            data.additional_supply_chain_actor_sequence_number,
          additionalSupplyChainActorRole:
            data.additional_supply_chain_actor_role,
          additionalSupplyChainActorIdentificationNumber:
            data.additional_supply_chain_actor_identification_number,
          locationOfGoodsTypeOfLocation:
            data.location_of_goods_type_of_location,
          locationOfGoodsQualifierOfIdentification:
            data.location_of_goods_qualifier_of_identification,
          locationOfGoodsCustomsOfficeReferenceNumber:
            data.location_of_goods_customs_office_reference_number,
          transportDocumentSequenceNumber:
            data.transport_document_sequence_number,
          transportDocumentType: data.transport_document_type,
          transportDocumentReferenceNumber:
            data.transport_document_reference_number,
          consignmentItemDeclarationGoodsItemNumber:
            data.consignment_item_declaration_goods_item_number,
        }}
      >
        {({ values }) => (
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBlock: 50,
            }}
          >
            <div className="form-position">
              {form.map(
                (field, index) =>
                  values[field.name] && <Field {...field} key={index} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
