import * as yup from "yup";
import { TextFormField } from "../../../../components/textform/TextFormField.tsx";
import { Formik, Field, Form } from "formik";
import moment from "moment";

export const validation = yup.object().shape({
  preparationDateAndTime: yup
    .string()
    .required("Preparation date and time is required"),
  messageType: yup.string().required("Message type is required"),
  presentationOfTheGoodsDateAndTime: yup
    .string()
    .required("Presentation of the goods date and time is required"),
  manifestReferenceNumber: yup
    .string()
    .required("Manifest reference number is required"),
  discrepanciesExist: yup.string().required("Discrepancies exit is required"),
  customsOfficeOfExitActualRefNumber: yup
    .string()
    .required("Customs office of exit actual ref number is required"),
  exitCarrierIdentificationNumber: yup
    .string()
    .required("Exit carrier identification number is required"),
  manifestItemNumber: yup.string().required("Manifest item number is required"),
  transportDocumentReference: yup
    .string()
    .required("Transport document reference is required"),
  typeOfPackages: yup.string().required("Type of packages is required"),
  numberOfPackages: yup.string().required("Number of packages is required"),
  grossMass: yup.string().required("Gross mass is required"),
  customsDataSequenceNumber: yup
    .string()
    .required("Customs data sequence number is required"),
  customsDataMRN: yup.string().required("MRN is required"),
  customsDataFinalOrPartialShipmentFlag: yup
    .string()
    .required("Final or partial shipment flag is required"),
  customsDataNumberOfPackages: yup
    .string()
    .required("Number of packages is required"),
  customsDataDeclarationGoodsItemNumber: yup
    .string()
    .required("Declaration goods item number is required"),
});

export default function IE547({ submitForm, data }) {
  const form = [
    {
      name: "preparationDateAndTime",
      label: "Preparation date and time",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageType",
      label: "Message type",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
    {
      name: "presentationOfTheGoodsDateAndTime",
      label: "Presentation Of the Goods Date and Time",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "manifestReferenceNumber",
      label: "Manifest Reference Number",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "discrepanciesExist",
      label: "Discrepancies Exist",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsOfficeOfExitActualRefNumber",
      label: "Customs Office of Exit Actual Ref Number",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "exitCarrierIdentificationNumber",
      label: "Exit Carrier Identification Number",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "manifestItemNumber",
      label: "Manifest Item Number",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "transportDocumentReference",
      label: "Transport Document Reference",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "typeOfPackages",
      label: "Type of Packages",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "numberOfPackages",
      label: "Number of Packages",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "grossMass",
      label: "Gross Mass",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsDataSequenceNumber",
      label: "Customs Data Sequence Number",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsDataMRN",
      label: "MRN",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsDataFinalOrPartialShipmentFlag",
      label: "Final or Partial Shipment Flag",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsDataNumberOfPackages",
      label: "Number of Packages",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsDataDeclarationGoodsItemNumber",
      label: "Declaration Goods Item Number",
      component: TextFormField,
      disabled: true,
      style: {
        width: "32%",
      },
    },
  ];

  return (
    <div>
      <Formik
        validationSchema={validation}
        initialValues={
          data.message_type == "CC515C"
            ? {
                preparationDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                messageType: "CC547C",
                presentationOfTheGoodsDateAndTime: "",
                manifestReferenceNumber: "",
                discrepanciesExist: "",
                customsOfficeOfExitActualRefNumber:
                  data.customs_office_of_exit_declared,
                exitCarrierIdentificationNumber: "",
                manifestItemNumber: "",
                transportDocumentReference: "",
                typeOfPackages: "",
                numberOfPackages: "",
                grossMass: "",
                customsDataSequenceNumber: "",
                customsDataMRN: data.DECLARATION.mrn,
                customsDataFinalOrPartialShipmentFlag: "",
                customsDataNumberOfPackages: "",
                customsDataDeclarationGoodsItemNumber:
                  data.GoodsItem.length.toString(),
              }
            : data.message_type == "CC570C"
            ? {
                preparationDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                messageType: "CC547C",
                presentationOfTheGoodsDateAndTime: "",
                manifestReferenceNumber: "",
                discrepanciesExist: "",
                customsOfficeOfExitActualRefNumber:
                  data.customs_office_of_exit_declared_reference_number,
                exitCarrierIdentificationNumber: "",
                manifestItemNumber: "",
                transportDocumentReference: "",
                typeOfPackages: "",
                numberOfPackages: "",
                grossMass: "",
                customsDataSequenceNumber: "",
                customsDataMRN: data.DECLARATION.mrn,
                customsDataFinalOrPartialShipmentFlag: "",
                customsDataNumberOfPackages: "",
                customsDataDeclarationGoodsItemNumber:
                  data.consignment_item_declaration_goods_item_number,
              }
            : {
                preparationDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                messageType: "CC547C",
                presentationOfTheGoodsDateAndTime: "",
                manifestReferenceNumber: "",
                discrepanciesExist: "",
                customsOfficeOfExitActualRefNumber:
                  data.customs_office_of_exit_declared_reference_number,
                exitCarrierIdentificationNumber: "",
                manifestItemNumber: "",
                transportDocumentReference: "",
                typeOfPackages: "",
                numberOfPackages: "",
                grossMass: "",
                customsDataSequenceNumber: "",
                customsDataMRN: data.DECLARATION.mrn,
                customsDataFinalOrPartialShipmentFlag: "",
                customsDataNumberOfPackages: "",
                customsDataDeclarationGoodsItemNumber:
                  data.consignment_item_declaration_goods_item_number,
              }
        }
        onSubmit={(values) => {
          submitForm(values);
        }}
      >
        {() => (
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBlock: 50,
            }}
          >
            <div className="form-position">
              {form.map((snapshot, key) => {
                return <Field {...snapshot} key={key} />;
              })}
            </div>
            <button
              style={{
                paddingTop: 10,
                alignSelf: "center",
                width: "35%",
                paddingBlock: 10,
              }}
              className="submit-form"
              type="submit"
            >
              Submit IE547 Declaration
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
