import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import { alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { json, useNavigate } from "react-router-dom";
import { addLog } from "../../services/logs";
import moment, { utc } from "moment";

function AccountPopover({
  info,
  office,
  open,
  handleClose,
  handleOpen,
  iconButtonRef,
}) {
  // const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  // const handleOpen = (event) => {
  //   setOpen(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setOpen(null);
  // };

  const logOut = async () => {
    const data = {
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      action: "Logout",
      action_details: "Logged out",
      email: info.email,
      office: JSON.parse(localStorage.getItem("selected_office")).name,
    };

    var jsonData = JSON.stringify(data);

    await addLog(jsonData);

    localStorage.removeItem("token");
    // localStorage.removeItem("selected_office");
    navigate("/login");
  };

  async function changeOffice(office) {
    const object = {
      id: office.ID,
      name: office.office_name,
      functionality: office.functionality,
    };

    const data = {
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      action: "Change Office",
      action_details: `Changed to ${office.office_name}`,
      email: info.email,
      office: JSON.parse(localStorage.getItem("selected_office")).name,
    };

    var jsonData = JSON.stringify(data);

    await addLog(jsonData);

    localStorage.setItem("selected_office", JSON.stringify(object));

    window.location.reload();
  }

  const getSelectedOfficeID = () => {
    const selectedOffice = localStorage.getItem("selected_office");
    if (selectedOffice) {
      return JSON.parse(selectedOffice).id;
    }
    return null;
  };

  return (
    <>
      <IconButton
        onClick={(e) => handleOpen(e)}
        sx={{
          marginRight: 2.5,
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
        ref={iconButtonRef}
      >
        <Avatar
          className="avatar-popover"
          src={info.photo}
          alt={info.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {info.name.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography
            variant="subtitle2"
            noWrap
            style={{ fontFamily: "SeagoeUIBold" }}
          >
            {info.name}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {office.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => changeOffice(item)}
            selected={getSelectedOfficeID() === item.ID}
            style={{ fontFamily: "SeagoeUI", fontSize: 15, marginBlock: 5 }}
          >
            {item.office_name}
          </MenuItem>
        ))}

        <Divider sx={{ borderStyle: "dashed", m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={logOut}
          sx={{ typography: "body2", color: "error.main", py: 1.5 }}
          style={{ fontFamily: "SeagoeUI" }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}

export default AccountPopover;
