import { TextFormField } from "../../../../components/textform/TextFormField.tsx";
import { Formik, Field, Form } from "formik";

export default function IE615({ data }) {
  const form = [
    {
      name: "messageSender",
      label: "Message Sender",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "messageRecipient",
      label: "Message Recipient",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "messageIdentification",
      label: "Message Identification",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "messageType",
      label: "Message Type",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "exportOperationStoringFlag",
      label: "Export Operation Storing Flag",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "customsOfficeOfExitDeclaredReferenceNumber",
      label: "Customs Office of Exit Declared Reference Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "customsOfficeOfLodgement",
      label: "Customs Office of Lodgement",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "declarantIdentificationNumber",
      label: "Declarant Identification Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consignmentContainerIndicator",
      label: "Consignment Container Indicator",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consignmentGrossMass",
      label: "Consignment Gross Mass",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "carrierIdentificationNumber",
      label: "Carrier Identification Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "transportEquipmentSequenceNumber",
      label: "Transport Equipment Sequence Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "transportEquipmentNumberOfSeals",
      label: "Transport Equipment Number of Seals",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "sealSequenceNumber",
      label: "Seal Sequence Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "sealIdentifier",
      label: "Seal Identifier",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "locationOfGoodsTypeOfLocation",
      label: "Location of Goods Type of Location",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "locationOfGoodsQualifierOfIdentification",
      label: "Location of Goods Qualifier of Identification",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "locationOfGoodsCustomsOfficeReferenceNumber",
      label: "Location of Goods Customs Office Reference Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "transportDocumentSequenceNumber",
      label: "Transport Document Sequence Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "transportDocumentType",
      label: "Transport Document Type",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "transportDocumentReferenceNumber",
      label: "Transport Document Reference Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consignmentItemDeclarationGoodsItemNumber",
      label: "Consignment Item Declaration Goods Item Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consigneeName",
      label: "Consignee Name",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consigneeAddressStreetAndNumber",
      label: "Consignee Address Street and Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consigneeAddressPostcode",
      label: "Consignee Address Postcode",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consigneeAddressCity",
      label: "Consignee Address City",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "consigneeAddressCountry",
      label: "Consignee Address Country",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "commodityDescriptionOfGoods",
      label: "Commodity Description of Goods",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "commodityCodeHarmonizedSystemSubHeadingCode",
      label: "Commodity Code Harmonized System Sub-Heading Code",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "commodityCodeCombinedNomenclatureCode",
      label: "Commodity Code Combined Nomenclature Code",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "goodsMeasureGrossMass",
      label: "Goods Measure Gross Mass",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "packagingSequenceNumber",
      label: "Packaging Sequence Number",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "packingTypeOfPackages",
      label: "Packing Type of Packages",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
    {
      name: "packagingShippingMarks",
      label: "Packaging Shipping Marks",
      readOnly: true,
      component: TextFormField,
      style: { width: "32%" },
    },
  ];

  let countryOfRoutingOfConsignment = JSON.parse(
    data.country_of_routing_of_consignment
  );

  return (
    <div>
      <Formik
        initialValues={{
          messageSender: data.message_sender,
          messageRecipient: data.message_recipient,
          messageIdentification: data.message_identification,
          messageType: data.message_type,
          exportOperationStoringFlag: data.export_operation_storing_flag,
          customsOfficeOfExitDeclaredReferenceNumber:
            data.customs_office_of_exit_declared_reference_number,
          customsOfficeOfLodgement: data.customs_office_of_lodgement,
          declarantIdentificationNumber: data.declarant_identification_number,
          consignmentContainerIndicator: data.consignment_container_indicator,
          consignmentGrossMass: data.consignment_gross_mass,
          carrierIdentificationNumber: data.carrier_identification_number,
          transportEquipmentSequenceNumber:
            data.transport_equipment_sequence_number,
          transportEquipmentNumberOfSeals:
            data.transport_equipment_number_of_seals,
          sealSequenceNumber: data.seal_sequence_number,
          sealIdentifier: data.seal_identifier,
          locationOfGoodsTypeOfLocation:
            data.location_of_goods_type_of_location,
          locationOfGoodsQualifierOfIdentification:
            data.location_of_goods_qualifier_of_identification,
          locationOfGoodsCustomsOfficeReferenceNumber:
            data.location_of_goods_customs_office_reference_number,
          countryOfRoutingOfConsignment: countryOfRoutingOfConsignment.map(
            (item) => ({
              sequenceNumber: item.SequenceNumber,
              country: item.Country,
            })
          ),
          transportDocumentSequenceNumber:
            data.transport_document_sequence_number,
          transportDocumentType: data.transport_document_type,
          transportDocumentReferenceNumber:
            data.transport_document_reference_number,
          consignmentItemDeclarationGoodsItemNumber:
            data.consignment_item_declaration_goods_item_number,
          consigneeName: data.consignee_name,
          consigneeAddressStreetAndNumber:
            data.consignee_address_street_and_number,
          consigneeAddressPostcode: data.consignee_address_postcode,
          consigneeAddressCity: data.consignee_address_city,
          consigneeAddressCountry: data.consignee_address_country,
          commodityDescriptionOfGoods: data.commodity_description_of_goods,
          commodityCodeHarmonizedSystemSubHeadingCode:
            data.commodity_code_harmonized_system_sub_heading_code,
          commodityCodeCombinedNomenclatureCode:
            data.commodity_code_combined_nomenclature_code,
          goodsMeasureGrossMass: data.goods_measure_gross_mass,
          packagingSequenceNumber: data.packaging_sequence_number,
          packingTypeOfPackages: data.packing_type_of_packages,
          packagingShippingMarks: data.packaging_shipping_marks,
        }}
      >
        {({ values }) => (
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBlock: 50,
            }}
          >
            <div className="form-position">
              {form.map(
                (field, index) =>
                  values[field.name] && <Field {...field} key={index} />
              )}
            </div>

            {values.countryOfRoutingOfConsignment &&
              values.countryOfRoutingOfConsignment.length > 0 && (
                <div style={{ width: "100%", marginTop: 20 }}>
                  <h3 style={{ fontFamily: "SeagoeUIBold" }}>
                    Country of Routing of Consignment
                  </h3>
                  <ul style={{ display: "flex", columnGap: 50 }}>
                    {values.countryOfRoutingOfConsignment.map((item, index) => (
                      <li key={index}>
                        <p style={{ fontFamily: "SeagoeUI" }}>
                          Sequence Number: {item.sequenceNumber}
                        </p>
                        <p style={{ fontFamily: "SeagoeUI" }}>
                          Country: {item.country}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
