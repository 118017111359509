import fetcher from "../helpers/fetcher";

export async function ReqExportXMLS2S(
  page,
  office_id,
  debouncedSearchTerm = ""
) {
  let path = `/export-declarations/create/total/s2s?office_id=${office_id}&page=${page}&search=${debouncedSearchTerm}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function CreateExportDeclarationXMLS2S(office_id, body, type) {
  let path = `/export-declarations/create/s2s?office_id=${office_id}&file_type=${type}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}

export async function CreateDeclarationS2S507(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/507?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}

export async function CreateDeclarationS2S590(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/590?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}

export async function CreateDeclarationS2S596(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/596?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}

export async function CreateDeclarationS2S511(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/511?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}

export async function CreateDeclarationS2S547(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/547?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}
export async function CreateDeclarationS2S513(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/513?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}
export async function CreateDeclarationS2S514(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/514?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}
export async function CreateDeclarationS2S583(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/583?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}

export async function CreateDeclarationS2S520(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/520?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}

export async function CreateDeclarationS2S613(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/613?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}

export async function CreateDeclarationS2S614(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/614?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}

export async function CreateDeclarationS2S573(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/573?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}

export async function CreateDeclarationS2S554(office_id, body, declaration_id) {
  let path = `/export-declarations/upload/s2s/554?office_id=${office_id}&declaration_id=${declaration_id}`;

  let request = await fetcher(path, "POST", body, true, true);

  let content = request.json();

  return content;
}

export async function fetchDeclarationData(declarationId, officeId) {
  let path = `/export-declarations/upload/s2s?office_id=${officeId}&declaration_id=${declarationId}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function DeleteXmlS2S(id, office_id) {
  let path = `/export-declarations/create/s2s/${id}?office_id=${office_id}`;

  let request = await fetcher(path, "DELETE");

  let content = request.json();

  return content;
}

export async function DownloadXmlS2S(id, lrn) {
  let path = `/export-declarations/create/s2s/${id}`;

  let request = await fetcher(path, "GET");

  const content = await request.blob();
  const csvURL = window.URL.createObjectURL(content);

  let tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", `${lrn}.xml`);
  tempLink.click();

  return content;
}

export async function NotificationToFalse(declarationId) {
  let path = `/export-declarations/create/notification-to-false/${declarationId}`;

  let request = await fetcher(path, "PUT");

  let content = request.json();

  return content;
}
